/* body {
  overflow: hidden;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.UrlCollapse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20ch;
}

.normal-white-space{
  white-space: normal;
}

.w-200px{
  width: 200px;
}

.w-150px{
  width: 150px;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 40ch;
}

.line-clamp p {
  margin-bottom: 0;
}

strong {
  font-weight: bold !important;
}

#noteModal .modal-body {
  height: 300px;
  overflow-y: auto;
}

.curser-pointer {
  cursor: pointer;
}

.curser-default {
  cursor: default;
}
.default-btn {
  background-color: #eaa827 !important;
  border: 0 !important;
}
.default-btn:hover {
  border: 0.5px solid rgb(68, 67, 67) !important;
  border-color: initial !important;
}

.document-table tr td {
  font-size: 16px !important;
}

.color-default {
  color: #eaa827 !important;
}

.color-red {
  color: red !important;
}

.color-white {
  color: white !important;
}

.react-pdf__Document,
.react-pdf__Page {
  min-height: auto !important;
  max-height: 1500px !important;
  margin-bottom: 24px;
}
.scrolled::-webkit-scrollbar {
  width: 0.5em;
}

.scrolled::-webkit-scrollbar-track {
  box-shadow: transparent;
}

.scrolled::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
}

.scrolled::-webkit-scrollbar-thumb {
  background-color: #506e8c;
  outline: 1px solid transparent;
  border-radius: 15px;
}

.active-candidate-pipeline {
  color: #eaa827 !important;
  border-bottom: 3px solid #eaa827 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/* .editor-wrapped {
  position: relative;
}
.quill {
  border-top: 1px solid #e5e9f8;
  border-bottom: 0;
}
.ql-toolbar {
  position: absolute;
  bottom: 44px;
  width: 100%;
  transform: translateY(100%);
}
.quill-custom-btn {
  margin-top: -37px;
  position: absolute;
  z-index: 100;
  right: 0;
  margin-right: 6px;
} */

.submitTag {
  font-size: 12px;
  padding: 11px;
  border-radius: 40px;
}

#submittedEmail .input-group-text {
  border-bottom: 0 !important;
  border-radius: 0 !important;
  font-size: 16px !important;
}
.styleCss .input-group-text {
  border: 0;
}

.otherMails:hover {
  text-decoration: underline !important;
}

.forMailRemove {
  position: absolute;
  right: 10px;
  color: white;
  cursor: pointer;
  bottom: 7px;
}

.blink_me {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.custom-file-upload {
  padding: 6px 12px;
  height: 10rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.custom-file-upload input[type='file'] {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.titile-count-message-color {
  color: #0e79b6;
}

.titile-count-message {
  font-size: 12px;
  justify-content: space-between;
  display: flex;
}
.titile-count-message small {
  font-size: 100%;
}

#stepBodyQuill span {
  color: black !important;
  background-color: transparent !important;
}

.text-link {
  color: #33475b;
}
.text-link:hover {
  text-decoration: underline !important;
  color: #f9b220;
}

.skillsSectionDetailDrawer {
  max-height: 10em;
  overflow: hidden;
  overflow-y: auto;
}

.skillsSectionDetailDrawer .checkboxStyle {
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  position: inherit;
  margin-right: 10px;
}
