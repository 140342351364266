@import "../../../styles/mixins";
.upload-btn-wrapper {
  position: relative;
  &.user-info {
    @include responsive(414px) {
      margin-bottom: 20px;
      width: 100%;
    }
    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      width: 290px;
      cursor: pointer;
      @include responsive(414px) {
        width: 100%;
      }
    }
  }
}

.bulk-actions {
  .form-group {
    margin-bottom: 0px;
  }
  .form-group-heading {
    display: none;
  }
}
